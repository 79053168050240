import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;

    this.swiperElement = this.element.querySelector('.swiper');
    this.prev = this.element.querySelector('.swiper-button-prev');
    this.next = this.element.querySelector('.swiper-button-next');
    this.activeSlide = this.element.querySelector('.swiper-slide-active');
    this.buttonFullScreen = this.element.querySelector('.c-swiper__button--open');



    this.settings = {
      loop: true,
      navigation: { nextEl: this.next, prevEl: this.prev },

      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        }
      }
    };

    this.swiper;
  }

  updateNavigation(swiper) {
    this.activeSlide = swiper.slides[swiper.activeIndex];
    this.activeSlideSrc = this.activeSlide.getAttribute('data-src');
    this.buttonFullScreen.setAttribute('href', this.activeSlideSrc);
  }

  mount() {

    Swiper.use([Navigation]);

    this.settings = {
      ...this.settings,
      loop: true,
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
    this.buttonFullScreen.href =this.activeSlideSrc;

  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
